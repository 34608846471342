<template>
    <div class="root-wrap">
         <div class="body-wrap fx-fill">
            <div class="fill-box">
                <coupon-info v-if="showCoupon" :couponInfo="couponInfo"></coupon-info>
                <van-empty v-else :image="require('@/assets/images/user/user_card_empty.png')" description="优惠券不存在" style="transform: translateY(50%)"></van-empty>
            </div>
        </div>
        <div class="footer-wrap">
            <van-button v-if="showCoupon" type="primary" round block @click="bindCoupon" :disabled="!showCoupon">确认绑定</van-button>
            <van-button v-else round block @click="$goPage(-1)">返 回</van-button>
        </div>
    </div>
</template>

<script>
import CouponInfo from '@/components/user/user-coupon-info'

import { queryCoupon, bindCoupon} from '@api/coupon-request';

export default {
    components: {
        CouponInfo
    },
    computed: {
        showCoupon() {
            return this.couponInfo ? Object.keys(this.couponInfo).length > 0 : false;
        }
    },
    data() {
        return {
            couponPass: this.$route.query.couponPass,
            couponInfo: null,
        }
    },
    methods: {
        queryCoupon() {
            let loadingToast = this.$toast.loading(this.$config.loadingMsg);
            queryCoupon({ couponPass: this.couponPass}).then((res) => {
                this.couponInfo = res;
            }).finally(() => loadingToast.clear());
        },
        bindCoupon() {
            bindCoupon({ code: this.couponPass}).then((res) => {
                this.$toast('绑定成功');
                setTimeout(() => {
                    this.$goPage(-1)
                }, 1000)
            })
        }
    },
    created() {
        this.queryCoupon();
    }
}
</script>

<style lang="scss" scoped>
    .body-wrap {
        position: relative;
    }
    .fill-box {
        padding: 10px;
    }

    .footer-wrap {
        background-color: #fff;
        padding: 20px;
        width: 100%;
    }
</style>
